import { Lampe } from "./Lampe";
import { Aktionsfeld } from "./Aktionsfeld";
import "./Aktionsfelder.css";
import { useState, useEffect } from "react";

export const Aktionsfelder = ({ isActive, landscape, mobile }) => {
  const [felder, setFelder] = useState([]);
  const [offset, setOffset] = useState(0);
  const [enabledButton, setEnabledButton] = useState("next");

  useEffect(() => {
    async function fetchFields() {
      const response = await fetch("/php/php/loadContent.php", {
        method: "POST",
        headers: {
          accept: "application/json",
        },
        body: JSON.stringify({
          contentID: 1,
          offset: offset,
          loadall: landscape,
        }),
      });
      const json = await response.json();
      setFelder(json);
    }
    fetchFields();
  }, [offset]);

  const handleBack = () => {
    if (enabledButton === "back") {
      setOffset(offset - 4);
      setEnabledButton("next");
    }
  };
  const handleNext = () => {
    if (enabledButton === "next") {
      setOffset(offset + 4);
      setEnabledButton("back");
    }
  };

  return (
    <div
      id="01_03_aktionsfelder"
      className="relative w-1180 flex h-auto max-w-full pt-10 pb-300 flex-col justify-start items-center ls:pb-100"
    >
      <Lampe modus={isActive ? "enabled" : "disabled"} landscape={landscape} />
      <h2 className={"prim_h2" + (isActive ? " active" : "")}>
        Bedarfsgerechte Lösungen <br />
        für die jeweilige Situation
      </h2>
      <div className="relative flex flex-col w-full h-auto pb-100 tablet:flex-none ls:px-5 mobile:px-2">
        {felder.length > 0 && !felder.includes(undefined) ? (
          <>
            {!landscape && !mobile && (
              <>
                <div className="relative flex p-5 flex-row justify-center items-stretch flex-none gap-x-10 tablet:p-3 tablet:gap-x-5">
                  <Aktionsfeld data={felder[0]} />
                  <div
                    id="aktionsfelder_trenner_vert1"
                    className="w-1px -mt-5 -mb-5 prim-trennergradient-1 ls:hidden"
                  ></div>
                  <div
                    id="aktionsfelder_trenner_hor"
                    className="absolute h-1px w-auto left-0 right-0 top-auto bottom-5 -mt-5 -mb-5 prim-trennergradient-2 ls:hidden"
                  ></div>
                  <Aktionsfeld data={felder[1]} />
                </div>
                <div className="relative flex p-5 flex-row justify-center items-stretch flex-none gap-x-10 tablet:p-3 tablet:gap-x-5">
                  <Aktionsfeld data={felder[2]} />
                  <div
                    id="aktionsfelder_trenner_vert2"
                    className="w-1px -mt-5 -mb-5 prim-trennergradient-3"
                  ></div>
                  <Aktionsfeld data={felder[3]} />
                </div>
              </>
            )}
            {(landscape || mobile) && (
              <>
                {felder.map((feld) => (
                  <Aktionsfeld key={feld.AktionsfeldID} data={feld} />
                ))}
              </>
            )}
          </>
        ) : (
          <p>Lädt...</p>
        )}

        <div className="absolute left-0 right-0 bottom-0 top-auto flex pt-10 pb-5 justify-center items-center gap-x-5 ls:hidden">
          <button
            onClick={() => handleBack()}
            id="aktionsfelder_navbar_back"
            className={
              "prim_button_onlyicon secondary" +
              (enabledButton === "back" ? "" : " disabled")
            }
          >
            <div className="w-5 h-5 w-embed">
              <svg
                width="18"
                height="18"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.61296 13C9.50997 13.0005 9.40792 12.9804 9.3128 12.9409C9.21767 12.9014 9.13139 12.8433 9.05902 12.7701L3.83313 7.54416C3.68634 7.39718 3.60388 7.19795 3.60388 6.99022C3.60388 6.78249 3.68634 6.58325 3.83313 6.43628L9.05902 1.21039C9.20762 1.07192 9.40416 0.996539 9.60724 1.00012C9.81032 1.00371 10.0041 1.08597 10.1477 1.22959C10.2913 1.37322 10.3736 1.56698 10.3772 1.77005C10.3808 1.97313 10.3054 2.16968 10.1669 2.31827L5.49496 6.99022L10.1669 11.6622C10.3137 11.8091 10.3962 12.0084 10.3962 12.2161C10.3962 12.4238 10.3137 12.6231 10.1669 12.7701C10.0945 12.8433 10.0083 12.9014 9.91313 12.9409C9.81801 12.9804 9.71596 13.0005 9.61296 13Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </button>
          <button
            onClick={() => handleNext()}
            id="aktionsfelder_navbar_next"
            className={
              "prim_button_onlyicon secondary" +
              (enabledButton === "next" ? "" : " disabled")
            }
          >
            <div className="h-5 w-5 w-embed">
              <svg
                width="18"
                height="18"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
