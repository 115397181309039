import "./App.css";
import { Header } from "./components";
import { AllRoutes } from "./routes/AllRoutes";
import { Footer } from "./components";
import { useWindowDimensions } from "./hooks/useWindowDimensions";
import { useState, useEffect } from "react";
import { ConfigProvider } from "./context/ConfigContext";

function App() {
  const [config, setConfig] = useState(null);
  const { height, width } = useWindowDimensions();
  let touch = false;
  width <= 990 ? (touch = true) : (touch = false);
  let landscape = false;
  width <= 767 ? (landscape = true) : (landscape = false);
  let mobile = false;
  width <= 479 ? (mobile = true) : (mobile = false);
  const [scrollTop, setScrollTop] = useState(0);
  const handleScroll = () => {
    setScrollTop(window.scrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.documentElement.classList.add("dark");
  }, []);

  // useEffect(() => {
  //   fetch("/config.json")
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Konfigurationsdatei nicht gefunden");
  //       }
  //       return response.json();
  //     })
  //     .then((configData) => {
  //       setConfig(configData);
  //     })
  //     .catch((error) => {
  //       console.error("Fehler beim Laden der Konfiguration:", error);
  //       // Setze hier Standardwerte oder führe eine Fehlerbehandlung durch
  //       setConfig({ apiBaseUrl: "../" }); // Beispiel für einen Standardwert
  //     });
  // }, []);

  // if (!config) {
  //   return <div>Lade Konfiguration...</div>;
  // }

  useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = "https://prim-agency.de/matomo/matomo/js/container_eA5EdmlK.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <div className="prim_body">
      <ConfigProvider>
        <Header scroll={scrollTop} fullvh={height} touch={touch} />
        <div className="allroutes">
          <AllRoutes
            scroll={scrollTop}
            fullvh={height}
            touch={touch}
            landscape={landscape}
            mobile={mobile}
          />
        </div>
        <Footer scroll={scrollTop} fullvh={height} touch={touch} />
      </ConfigProvider>
    </div>
  );
}

export default App;
