import logo_small from "../../assets/images/welcome_logo_small.png";
import logo_medium from "../../assets/images/welcome_logo_medium.png";
import logo_big from "../../assets/images/welcome_logo_big.png";

export const Footer = () => {
  return (
    <div className="relative z-10 block h-auto mt-auto pb-0 justify-between items-center bg-layout">
      <div className="w-container flex py-5 justify-between items-center tablet:max-w-full mobile:flex-col mobile:justify-start laptop:max-w-6xl">
        <a
          href="mailto:a.schaefer@primagency.de?subject=Serviceanfrage"
          className="prim_button_onlytext primary marginbottommobile w-button"
        >
          Service erhalten
        </a>
        <a
          href="/legal-notice"
          target="_blank"
          className="prim_button_onlytext secondary marginbottommobile w-button"
        >
          Impressum
        </a>
        <div className="flex flex-row p-0 justify-start items-center">
          <img
            src={logo_big}
            loading="lazy"
            sizes="(max-width: 767px) 25.000001907348633px, (max-width: 991px) 3vw, (max-width: 1279px) 25.000001907348633px, (max-width: 1439px) 2vw, (max-width: 1919px) 25.000001907348633px, 1vw"
            srcSet={`
            ${logo_small} 500w,
            ${logo_medium} 800w,
            ${logo_big} 935w
          `}
            alt=""
            className="w-auto h-6 mr-3"
          />
          <div className="py-0 text-xl text-center">PRIM</div>
        </div>
      </div>
    </div>
  );
};
