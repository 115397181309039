import ln1 from "../assets/images/legal_notice_1.png";
import ln2_1 from "../assets/images/legal_notice_2_1.png";
import ln2_2 from "../assets/images/legal_notice_2_2.png";
import ln3 from "../assets/images/legal_notice_3.png";
import ln4 from "../assets/images/legal_notice_4.png";
import ln5_1 from "../assets/images/legal_notice_5_1.png";
import ln5_2 from "../assets/images/legal_notice_5_2.png";
import ln6 from "../assets/images/legal_notice_6.png";

export const LegalNotice = () => {
  return (
    <div className="container-40 w-container">
      <h1 className="prim_h1">Impressum</h1>
      <h2 className="prim_impressum_kategorie">Dienstanbieter:</h2>
      <img
        src={ln1}
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 631px"
        srcSet={`${ln2_1} 500w, ${ln2_2} 631w`}
        alt=""
        className="prim_impressum"
      />
      <img src={ln3} loading="lazy" alt="" className="prim_impressum mobile" />
      <h2 className="prim_impressum_kategorie">Kontakt:</h2>
      <img
        src={ln4}
        loading="lazy"
        sizes="(max-width: 767px) 100vw, 628px"
        srcSet={`${ln5_1} 500w, ${ln5_2} 628w`}
        alt=""
        className="prim_impressum"
      />
      <img src={ln6} loading="lazy" alt="" className="prim_impressum mobile" />
    </div>
  );
};
