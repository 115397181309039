import placeholder from "../../../assets/images/aktionsfelder_placeholder.svg";

export const Aktionsfeld = ({ data }) => {
  const { AktionsfeldID, Bild, Text, Titel } = data;
  return (
    <div className="flex max-w-none py-5 px-0 flex-1 gap-x-4 mobile:gap-x-3">
      <div className="aktionsfelder_item_imgdiv">
        {Bild.length === 0 ? (
          <img src={placeholder} loading="lazy" alt="" className="w-16 h-16" />
        ) : (
          <img src={Bild} loading="lazy" alt="" className="w-16 h-16" />
        )}
      </div>
      <div className="flex-1">
        <div className="prim_item_title">{Titel}</div>
        <p className="prim_item_text">{Text}</p>
      </div>
    </div>
  );
};
