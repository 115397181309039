import { Routes, Route } from "react-router-dom";
import { LegalNotice, Main, Legal, Karriere } from "../pages";

export const AllRoutes = ({ scroll, fullvh, touch, landscape, mobile }) => {
  return (
    <div className="prim_body">
      <Routes>
        <Route
          path="/"
          element={
            <Main
              scroll={scroll}
              fullvh={fullvh}
              touch={touch}
              landscape={landscape}
              mobile={mobile}
            />
          }
        />
        <Route
          path="/legal-notice"
          element={
            <LegalNotice touch={touch} landscape={landscape} mobile={mobile} />
          }
        />
        <Route
          path="/legal"
          element={
            <Legal touch={touch} landscape={landscape} mobile={mobile} />
          }
        />
        <Route
          path="/karriere-aufbauen"
          element={
            <Karriere touch={touch} landscape={landscape} mobile={mobile} />
          }
        />
      </Routes>
    </div>
  );
};
