import logo_small from "../../assets/images/welcome_logo_small.png";
import logo_medium from "../../assets/images/welcome_logo_medium.png";
import logo_big from "../../assets/images/welcome_logo_big.png";
import group from "../../assets/images/group.svg";
import { Link } from "react-router-dom";

export const Header = ({ scroll, fullvh, touch }) => {
  return (
    <div>
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration="400"
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="w-nav fixed top-0 left-0 right-0 bottom-auto bg-layout z-50 tablet:hidden"
      >
        <div className="ml-8">
          <div className="flex pl-0 float-left items-center w-clearfix">
            <Link to="/" className="p-2 w-nav-brand mr-8">
              <img
                src={logo_big}
                loading="lazy"
                sizes="(max-width: 767px) 100vw, (max-width: 1439px) 50.000003814697266px, (max-width: 1919px) 3vw, 50.000003814697266px"
                srcSet={`
                ${logo_small} 500w,
                ${logo_medium} 800w,
                ${logo_big} 935w
              `}
                alt="PRIM Logo"
                className="h-12 w-auto"
              />
            </Link>
            <a
              href="mailto:a.schaefer@primagency.de?subject=Serviceanfrage"
              className="prim_button_onlytext primary floatleft w-button mr-4"
            >
              Service erhalten
            </a>
            <Link
              to="/karriere-aufbauen"
              className="prim_button_onlytext primary floatleft w-button"
            >
              Karriere aufbauen
            </Link>
          </div>

          <nav role="navigation" className="items-stretch hidden w-nav-menu">
            <div data-hover="false" data-delay="0" className="mx-3 w-dropdown">
              <div className="w-40 bg-dropdown text-white text-center w-dropdown-toggle">
                <div className="prim_navbar_dropdown_icon w-icon-dropdown-toggle"></div>
                <div className="prim_navbar_dropdown_text">Service</div>
              </div>
              <nav className="prim_navbar_dropdown_list w-dropdown-list">
                <a href="/" className="w-dropdown-link">
                  Link 1
                </a>
                <a href="/" className="w-dropdown-link">
                  Link 2
                </a>
                <a href="/" className="w-dropdown-link">
                  Link 3
                </a>
              </nav>
            </div>
            <div data-hover="false" data-delay="0" className="mx-3 w-dropdown">
              <div className="w-40 bg-dropdown text-white text-center w-dropdown-toggle">
                <div className="prim_navbar_dropdown_icon w-icon-dropdown-toggle"></div>
                <div className="prim_navbar_dropdown_text">Expertise</div>
              </div>
              <nav className="prim_navbar_dropdown_list w-dropdown-list">
                <a href="/" className="w-dropdown-link">
                  Link 1
                </a>
                <a href="/" className="w-dropdown-link">
                  Link 2
                </a>
                <a href="/" className="w-dropdown-link">
                  Link 3
                </a>
              </nav>
            </div>
            <div data-hover="false" data-delay="0" className="mx-3 w-dropdown">
              <div className="w-40 bg-dropdown text-white text-center w-dropdown-toggle">
                <div className="prim_navbar_dropdown_icon w-icon-dropdown-toggle"></div>
                <div className="prim_navbar_dropdown_text">Portfolio</div>
              </div>
              <nav className="prim_navbar_dropdown_list w-dropdown-list">
                <a href="/" className="w-dropdown-link">
                  Link 1
                </a>
                <a href="/" className="w-dropdown-link">
                  Link 2
                </a>
                <a href="/" className="w-dropdown-link">
                  Link 3
                </a>
              </nav>
            </div>
            <a
              href="/"
              className="mx-3 p-4 bg-dropdown flex-grow-0 basis-auto w-inline-block"
            >
              <img
                src={group}
                loading="lazy"
                alt=""
                className="w-7 h-7 invert"
              />
            </a>
          </nav>
          <div className="w-nav-button">
            <div className="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>

      <div
        className={
          "hidden tablet:fixed left-0 right-0 top-0 bottom-auto z-10 tablet:flex h-16 pl-3 justify-start items-center transition-colors duration-600 ease-linear" +
          (scroll === 0 ? " bg-layout" : " bg-transparent ")
        }
      >
        <div className="tablet:static tablet:left-auto tablet:top-3 tablet:right-3 tablet:bottom-auto tablet:z-20 tablet:block tablet:h-auto tablet:rounded-md tablet:bg-transparent landscape:top-3 landscape:right-3 w-clearfix">
          <a
            href="mailto:a.schaefer@primagency.de?subject=Serviceanfrage"
            className="prim_button_onlytext primary floatleft w-button mr-4"
          >
            Service erhalten
          </a>
          <Link
            to="/karriere-aufbauen"
            className="prim_button_onlytext primary floatleft w-button"
          >
            Karriere aufbauen
          </Link>
        </div>
      </div>
    </div>
  );
};
