export const Ansatzfeld = ({
  id,
  daten,
  active,
  handleMouseEnter,
  last = "0",
}) => {
  const { title, text, textmobile, bild } = daten;

  return (
    <div
      className=" relative flex flex-col justify-center items-stretch flex-1 tablet:block tablet:px-10 tablet:py-5 tablet:flex-row"
      onMouseEnter={() => handleMouseEnter(id)}
    >
      {last === "0" && (
        <div
          className={`absolute left-auto top-0 right-0 bottom-0 w-1px h-auto bg-transparent ansatzfelder_trenner_vert_${id} tablet:left-0 tablet:right-0 tablet:bottom-0 tablet:top-auto tablet:w-auto tablet:h-1px ls:top-auto ls:bottom-0 ls:left-0 ls:right-0 mobile:top-auto mobile:left-0 mobile:right-0 mobile:bottom-0`}
        ></div>
      )}

      <div className="prim_item_title center padding">{title}</div>
      <div
        className={
          "absolute left-0 right-0 top-0 bottom-0 z-20 px-1 pb-1 bg-white flex-col" +
          (id === active ? " biggerthantablet:flex tablet:hidden" : " hidden")
        }
      >
        <div className="prim_item_title center dark padding">{title}</div>
        <p className="prim_item_text dark center">
          {text.map((line, index) => (
            <span key={index}>
              {line}
              <br /> <br />
            </span>
          ))}
        </p>
        <img
          src={bild}
          loading="lazy"
          alt=""
          className="absolute w-11/12 mx-auto pl-2 top-auto bottom-0 z-10 h-200 opacity-10"
        />
      </div>
      <div className="prim_item_text center touch">{textmobile}</div>
    </div>
  );
};
