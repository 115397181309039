import "./Welcome.css";
import { useState } from "react";
import logo_small from "../../../assets/images/welcome_logo_small.png";
import logo_medium from "../../../assets/images/welcome_logo_medium.png";
import logo_big from "../../../assets/images/welcome_logo_big.png";

export const Welcome = ({ scroll, fullvh, touch }) => {
  const [startLight, setStartLight] = useState(0);

  return (
    <>
      <div id="01_01_home" className="home wf-section">
        <div className="mb-16 ls:mb-0 ls:ml-3 mobile:mb-10 mobile:ml-0 mobile:pl-5 laptop:mr-64 laptop:mb-0">
          <h1 className="prim_h1">PRIM schafft Effizienz</h1>
          <div className="prim_h1_sub">
            Optimierte Prozesse &amp; Maßgeschneiderte Software
          </div>
        </div>
        <img
          src={logo_big}
          loading="lazy"
          sizes="(max-width: 767px) 70px, 250.00001525878906px"
          srcSet={`
          ${logo_small} 500w,
          ${logo_medium} 800w,
          ${logo_big} 935w
        `}
          alt=""
          className="w-auto h-64 ls:h-20 ls:mr-5 ls:ml-10 mobile:mx-0"
        />
        <div
          id="prim_start_sec"
          className="absolute left-0 right-0 bottom-0 top-auto flex justify-center items-center opacity-100 py-10 cursor-pointer"
        >
          <div
            className={
              "absolute left-0 right-0 top-0 bottom-0 prim_start_sec_light transition-opacity duration-500" +
              (startLight === 1 && scroll === 0 ? " opacity-100" : " opacity-0")
            }
          ></div>
          <div
            id="prim_start"
            className={
              "relative z-10 w-12 h-12 p-3 text-white transition-opacity duration-500 w-embed" +
              (scroll === 0 ? " visible opacity-100" : " invisible opacity-0")
            }
            onMouseEnter={() => setStartLight(1)}
            onMouseLeave={() => setStartLight(0)}
            onClick={() => {
              touch
                ? window.scrollTo({ top: fullvh, behavior: "smooth" })
                : window.scrollTo({ top: fullvh - 60, behavior: "smooth" });
            }}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.01744 10.398C6.91269 10.3985 6.8089 10.378 6.71215 10.3379C6.61541 10.2977 6.52766 10.2386 6.45405 10.1641L1.13907 4.84913C1.03306 4.69404 0.985221 4.5065 1.00399 4.31958C1.02276 4.13266 1.10693 3.95838 1.24166 3.82747C1.37639 3.69655 1.55301 3.61742 1.74039 3.60402C1.92777 3.59062 2.11386 3.64382 2.26584 3.75424L7.01744 8.47394L11.769 3.75424C11.9189 3.65709 12.097 3.61306 12.2748 3.62921C12.4527 3.64535 12.6199 3.72073 12.7498 3.84328C12.8797 3.96582 12.9647 4.12842 12.9912 4.30502C13.0177 4.48162 12.9841 4.662 12.8958 4.81724L7.58083 10.1322C7.50996 10.2125 7.42344 10.2775 7.32656 10.3232C7.22968 10.3689 7.12449 10.3944 7.01744 10.398Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};
