import { Lampe } from "./Lampe";
import "./Ansatz.css";
import { useState, useEffect, useRef } from "react";
import { Ansatzfeld } from "./Ansatzfeld";
import icon1 from "../../../assets/images/ana.svg";
import icon2 from "../../../assets/images/opt.svg";
import icon3 from "../../../assets/images/ui.svg";
import icon4 from "../../../assets/images/Git.svg";
import icon5 from "../../../assets/images/lcm.svg";

export const Ansatz = ({ isActive, touch, landscape }) => {
  const [lightState, setLightState] = useState(1);
  const [activeindex, setActiveindex] = useState("");
  const timeout_light_1 = useRef(null);
  const timeout_light_2 = useRef(null);
  const interval_light = useRef(null);

  const data = [
    {
      title: "Analyse",
      text: [
        "Ausgehend von Ihren spezifischen Prozessen führen wir eine Prozessanalyse durch.",
        "Hierbei modellieren wir Ihre Prozesse, werten Ihre Daten aus & erarbeiten das für Sie passende Zielbild.",
        "Basierend auf dem Ergebnis schlagen wir einen Potenzialbereich für das weitere Projekt vor.",
      ],
      textmobile:
        "Ausgehend von Ihren spezifischen Prozessen führen wir eine Prozessanalyse durch. Hierbei modellieren wir Ihre Prozesse, werten Ihre Daten aus und erarbeiten das für Sie passende Zielbild. Basierend auf dem Ergebnis schlagen wir einen Potenzialbereich für das weitere Projekt vor.",
      bild: icon1,
    },
    {
      title: "Optimierung",
      text: [
        "Mittels des strukturierten Einsatzes ausgewählter Lean-Instrumente & nach der Six Sigma Vorgehensweise optimieren wir Ihre Prozesse.",
        "Entscheidungsrelevante Kennzahlen werden visualisiert & entsprechende Maßnahmen werden abgeleitet.",
      ],
      textmobile:
        "Mittels des strukturierten Einsatzes ausgewählter Lean-Instrumente und nach der Six Sigma Vorgehensweise optimieren wir Ihre Prozesse. Entscheidungsrelevante Kennzahlen werden visualisiert und entsprechende Maßnahmen werden abgeleitet.",
      bild: icon2,
    },
    {
      title: "Design",
      text: [
        "In iterativen Schritten entwickeln wir das User Interface (UI) und die User Experience (UX) sowohl für analoge als auch digitale Tools.",
        "Die Orientierung an den zukünftigen Anwendern sowie die Prozessnähe stehen hierbei im Vordergrund.",
        "Ein benutzerfreundliches, attraktives & performantes Design ist das Resultat.",
      ],
      textmobile:
        "In iterativen Schritten entwickeln wir das User Interface (UI) und die User Experience (UX) sowohl für analoge als auch digitale Tools. Die Orientierung an den zukünftigen Anwendern sowie die Prozessnähe stehen hierbei im Vordergrund. Ein benutzerfreundliches, attraktives und performantes Design ist das Resultat.",
      bild: icon3,
    },
    {
      title: "Entwicklung",
      text: [
        "Technologieoffen wird die Infrastruktur des angestrebten digitalen Tools auf Ihre Bedürfnisse und bereits bestehende Strukturen abgestimmt.",
        "Nun entwickeln wir basierend auf dem zuvor konzipierten Design und der gewählten Infrastruktur Frontend sowie Backend der für Sie maßgeschneiderten Lösung.",
      ],
      textmobile:
        "Technologieoffen wird die Infrastruktur des angestrebten digitalen Tools auf Ihre Bedürfnisse und bereits bestehende Strukturen abgestimmt. Nun entwickeln wir basierend auf dem zuvor konzipierten Design und der gewählten Infrastruktur Frontend sowie Backend der für Sie maßgeschneiderten Lösung.",
      bild: icon4,
    },
    {
      title: "Betrieb",
      text: [
        "Um die Potenziale analoger und digitaler Tools zielführend nutzen zu können, ist es essentiell, dass Prozesse (wie z.B. das Performance-Management) gelebt werden.",
        "Wir unterstützen Sie sowohl beim Nachhalten eingeführter Strukturen als auch beim Betrieb von Software.",
      ],
      textmobile:
        "Um die Potenziale analoger und digitaler Tools zielführend nutzen zu können, ist es essentiell, dass Prozesse (wie z.B. das Performance-Management) gelebt werden. Wir unterstützen Sie sowohl beim Nachhalten eingeführter Strukturen als auch beim Betrieb von Software.",
      bild: icon5,
    },
  ];

  useEffect(() => {
    if (timeout_light_1.current) {
      clearTimeout(timeout_light_1);
    }
    if (timeout_light_2.current) {
      clearTimeout(timeout_light_2);
    }
    if (interval_light.current) {
      clearInterval(interval_light);
    }

    if (isActive) {
      setLightState(1);
      timeout_light_1.current = setTimeout(() => {
        setLightState(2);
        timeout_light_2.current = setTimeout(() => {
          setLightState(3);
        }, 2000);
      }, 50);

      interval_light.current = setInterval(() => {
        setLightState(1);
        timeout_light_1.current = setTimeout(() => {
          setLightState(2);
          timeout_light_2.current = setTimeout(() => {
            setLightState(3);
          }, 2000);
        }, 50);
      }, 3500);
      return () => {
        if (timeout_light_1.current) {
          clearTimeout(timeout_light_1);
        }
        if (timeout_light_2.current) {
          clearTimeout(timeout_light_2);
        }
        if (interval_light.current) {
          clearInterval(interval_light);
        }
      };
    }
  }, [isActive]);

  const handleMouseEnter = (id) => {
    if (!touch) {
      setActiveindex(id);
    } else {
      setActiveindex("");
    }
  };

  return (
    <div
      id="01_04_ansatz"
      className="relative flex w-1180 h-780 max-w-full pt-10 pb-0 flex-col justify-start items-center tablet:h-auto"
    >
      <Lampe modus={isActive ? "enabled" : "disabled"} landscape={landscape} />
      <h2 className={"prim_h2" + (isActive ? " active" : "")}>
        Mit iterativem Prozess
        <br />
        zur kontinuierlichen Verbesserung
      </h2>
      <div className="relative flex w-full h-550 flex-col gap-x-0 tablet:h-auto tablet:flex-none">
        <div className=" relative flex mb-10 flex-1 tablet:flex-col">
          <div className="absolute left-0 top-172px right-0 bottom-auto h-1px bg-transparent ansatzfelder_trenner_hor1 tablet:left-40% tablet:top-0 tablet:bottom-0 tablet:right-auto tablet:hidden tablet:w-1px tablet:h-auto tablet:ansatzfelder_trenner_hor1_tablet"></div>
          <div className="absolute left-0 top-275px right-0 bottom-auto h-1px bg-transparent ansatzfelder_trenner_hor2 tablet:left-auto tablet:top-0 tablet:bottom-0 tablet:right-40% tablet:hidden tablet:w-1px tablet:h-auto tablet:ansatzfelder_trenner_hor2_tablet"></div>
          <div className="absolute left-0 right-0 bottom-auto top-41px z-10 w-auto h-1px -mx-1px bg-lightblue rotate-4 tablet:left-4 tablet:top-0 tablet:right-auto tablet:bottom-0 tablet:w-1px tablet:h-auto tablet:-mx-1px tablet:my-0 tablet:-rotate-3 ls:left-5 ls:-rotate-2 mobile:left-3 mobile:-rotate-1/2">
            <div
              id="ansatzfelder_light_oben"
              className={
                "absolute -top-1px right-auto bottom-auto w-100 h-2px rounded-none bi-grad tablet:w-2px tablet:h-100 tablet:bi-grad-tablet-left ls:bi-grad-ls-left mobile:bi-grad-mobile-left" +
                (lightState === 1
                  ? " left-0 tablet:-left-1px tablet:top-0"
                  : lightState === 2
                  ? " biggerthantablet:left-[calc(100%-100px)] biggerthantablet:transition-left duration-2000 tablet:left-0 tablet:right-0 tablet:top-[calc(100%-100px)] tablet:transition-top"
                  : " opacity-0 biggerthantablet:left-0 transition-none tablet:top-0")
              }
            ></div>
          </div>
          <div className="absolute left-0 right-0 bottom-41px top-auto z-10 w-auto h-1px -mx-1px bg-lightblue rotate-176 tablet:left-auto tablet:top-0 tablet:right-4 tablet:bottom-0 tablet:w-1px tablet:h-auto tablet:-mx-1px tablet:my-0 tablet:rotate-3 ls:right-5 ls:rotate-2 mobile:right-3 mobile:rotate-1/2">
            <div
              id="ansatzfelder_light_unten"
              className={
                "absolute -top-1px left-auto bottom-auto w-100 h-2px rounded-none bi-grad  tablet:w-2px tablet:h-100 tablet:bi-grad-tablet-right ls:bi-grad-ls-right mobile:bi-grad-mobile-right" +
                (lightState === 1
                  ? " right-0 tablet:-right-1px tablet:top-0"
                  : lightState === 2
                  ? " biggerthantablet:right-[calc(100%-100px)] biggerthantablet:transition-right duration-2000 tablet:left-0 tablet:right-0 tablet:top-[calc(100%-100px)] tablet:transition-top"
                  : " opacity-0 biggerthantablet:right-0 transition-none tablet:top-0 ")
              }
            ></div>
          </div>

          <Ansatzfeld
            id="1"
            daten={data[0]}
            active={activeindex}
            handleMouseEnter={handleMouseEnter}
          />
          <Ansatzfeld
            id="2"
            daten={data[1]}
            active={activeindex}
            handleMouseEnter={handleMouseEnter}
          />
          <Ansatzfeld
            id="3"
            daten={data[2]}
            active={activeindex}
            handleMouseEnter={handleMouseEnter}
          />
          <Ansatzfeld
            id="4"
            daten={data[3]}
            active={activeindex}
            handleMouseEnter={handleMouseEnter}
          />
          <Ansatzfeld
            id="5"
            daten={data[4]}
            active={activeindex}
            handleMouseEnter={handleMouseEnter}
            last="1"
          />
        </div>
        <div className="block py-5 bg-white opacity-0 text-darkblue text-lg font-semibold text-center">
          E2E-Framework
        </div>
      </div>
    </div>
  );
};
