import React, { createContext, useContext, useState, useEffect } from "react";

// Erstelle den Context
const ConfigContext = createContext();

// Erstelle einen Provider für den Context
export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Konfigurationsdatei nicht gefunden");
        }
        return response.json();
      })
      .then(setConfig)
      .catch((error) => {
        console.error("Fehler beim Laden der Konfiguration:", error);
        setConfig({ apiBaseUrl: "http://localhost/prim" }); // Standardwerte
      });
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

// Erstelle einen Hook, um den Context zu verwenden
export const useConfig = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error(
      "useConfig muss innerhalb eines ConfigProvider verwendet werden"
    );
  }
  return context;
};
