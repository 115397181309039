import { useEffect, useState, useRef } from "react";
import {
  Welcome,
  Intersection,
  Aktionsfelder,
  Ansatz,
  Ergebnisse,
  SectionWrapper,
} from "./components";

export const Main = ({ scroll, fullvh, touch, landscape, mobile }) => {
  const welcomeRef = useRef(null);
  const intersectionRef = useRef(null);
  const aktionsfelderRef = useRef(null);
  const ansatzRef = useRef(null);
  const ergebnisseRef = useRef(null);

  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const options = {
      rootMargin: "-50% 0px -50% 0px",
      threshold: 0,
    };

    const observer = new IntersectionObserver(callback, options);

    if (welcomeRef.current) {
      observer.observe(welcomeRef.current);
    }
    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }
    if (aktionsfelderRef.current) {
      observer.observe(aktionsfelderRef.current);
    }
    if (ansatzRef.current) {
      observer.observe(ansatzRef.current);
    }
    if (ergebnisseRef.current) {
      observer.observe(ergebnisseRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div>
      <SectionWrapper id="welcome" ref={welcomeRef}>
        <Welcome
          scroll={scroll}
          fullvh={fullvh}
          touch={touch}
          id="welcome"
          isActive={activeSection === "welcome"}
        />
      </SectionWrapper>

      <div className="prim_container w-container">
        <SectionWrapper id="intersection" ref={intersectionRef}>
          <Intersection
            landscape={landscape}
            mobile={mobile}
            id="intersection"
            isActive={activeSection === "intersection"}
          />
        </SectionWrapper>

        <SectionWrapper id="aktionsfelder" ref={aktionsfelderRef}>
          <Aktionsfelder
            landscape={landscape}
            mobile={mobile}
            touch={touch}
            id="aktionsfelder"
            isActive={activeSection === "aktionsfelder"}
          />
        </SectionWrapper>

        <SectionWrapper id="ansatz" ref={ansatzRef}>
          <Ansatz
            landscape={landscape}
            touch={touch}
            id="ansatz"
            isActive={activeSection === "ansatz"}
          />
        </SectionWrapper>

        <SectionWrapper id="ergebnisse" ref={ergebnisseRef}>
          <Ergebnisse
            landscape={landscape}
            touch={touch}
            id="ergebnisse"
            isActive={activeSection === "ergebnisse"}
          />
        </SectionWrapper>
      </div>
    </div>
  );
};
