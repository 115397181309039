import { Lampe } from "./Lampe";
import "./Ergebnisse.css";
import sechseck from "../../../assets/images/prim_sechseck.svg";

export const Ergebnisse = () => {
  return (
    <div id="01_05_ergebnisse" className="_01_05_ergebnisse last wf-section">
      <Lampe />
      <h2 className="prim_h2">Projekte</h2>
      <div className="prim_content_ergebnisse">
        <div id="ergebnisse_item" className="ergebnisse_item">
          <div className="ergebnisse_item_column">
            <h3 id="ergebnisse_item_titel" className="prim_h3">
              Produktionsplanung
            </h3>
            <div className="w-layout-grid ergebnisse_item_infogrid">
              <div className="prim_item_bezeichner">Kunde:</div>
              <div id="ergebnisse_item_kunde" className="prim_item_text">
                Merck KGaA (Healthcare)
              </div>
              <div className="prim_item_bezeichner">Bereich:</div>
              <div id="ergebnisse_item_bereich" className="prim_item_text">
                Datenkonsolidierung
              </div>
            </div>
            <div id="ergebnisse_item_beschreibung" className="prim_item_text">
              Von einem intransparenten, manuell gepflegten Prozess wurde eine
              Transformation zu einer volldigitalen Lösung realisiert.
            </div>
            <div className="ergebnisse_item_themen">
              <a
                id="ergebnisse_item_thema_button"
                href="#"
                className="prim_button_onlytext primary hidden ergebnisse_item_thema_button w-button"
              >
                Prozessmodellierung
              </a>
            </div>
          </div>
          <div className="ergebnisse_item_column right">
            <div className="browser">
              <div className="browser_tabs">
                <div id="browser_tab" className="browser_tab">
                  <div className="browser_tab_trenner"></div>
                </div>
              </div>
              <div className="browser_window">
                <img
                  src={sechseck}
                  loading="lazy"
                  alt=""
                  className="image-225"
                />
              </div>
            </div>
            <div className="ergebnisse_item_themen_mobile">
              <div
                id="ergebnisse_item_thema_mobile"
                className="ergebnisse_item_thema_mobile"
              >
                <img
                  src={sechseck}
                  loading="lazy"
                  id="ergebnisse_item_thema_mobile_pic"
                  alt=""
                  className="ergebnisse_item_thema_mobile_pic"
                />
                <div
                  id="ergebnisse_item_thema_mobile_titel"
                  className="prim_item_text center"
                >
                  Von einem intransparenten, manuell gepflegten Prozess wurde
                  eine Transformation zu einer volldigitalen Lösung realisiert.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
