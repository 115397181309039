import React, { useEffect, useState } from "react";
import { FaFileContract } from "react-icons/fa";
import { FaUserSecret } from "react-icons/fa6";

import { useHttpClient } from "../../hooks/useHttpClient";
import { LegalCard } from "./components/LegalCard";
import { Card, Tabs } from "flowbite-react";

export const Legal = () => {
  const [loadedAgbs, setLoadedAgbs] = useState([]);
  const [loadedDsrs, setLoadedDsrs] = useState([]);
  const { sendRequest } = useHttpClient();
  useEffect(() => {
    const fetchLegal = async () => {
      try {
        const responseData = await sendRequest(
          "/php/php/loadContent.php",
          "POST",
          { contentID: 2 }
        );
        setLoadedAgbs(responseData[0]);
        setLoadedDsrs(responseData[1]);
      } catch (err) {}
    };
    fetchLegal();
  }, [sendRequest]);

  return (
    <div className="container-40 w-container">
      <Tabs aria-label="Tabs with underline" style="underline">
        <Tabs.Item active title="AGB" icon={FaFileContract} className="w-4">
          {loadedAgbs.length > 0 && !loadedAgbs.includes(undefined) ? (
            <>
              {loadedAgbs != 0 ? (
                loadedAgbs.map((agb, index) => (
                  <React.Fragment key={agb.id}>
                    <LegalCard agb={agb} art="agb" />
                  </React.Fragment>
                ))
              ) : (
                <Card className="my-4">
                  <p className="mb-4">Keine Agbs gefunden</p>
                </Card>
              )}
            </>
          ) : (
            <p>Lädt...</p>
          )}
        </Tabs.Item>
        <Tabs.Item
          title="Datenschutzrichtlinie"
          icon={FaUserSecret}
          className="w-4"
        >
          {loadedDsrs.length > 0 && !loadedDsrs.includes(undefined) ? (
            <>
              {loadedDsrs != 0 ? (
                loadedDsrs.map((agb, index) => (
                  <React.Fragment key={agb.id}>
                    <LegalCard agb={agb} art="dsr" />
                  </React.Fragment>
                ))
              ) : (
                <Card className="my-4">
                  <p className="mb-4">Keine Dsrd gefunden</p>
                </Card>
              )}
            </>
          ) : (
            <p>Lädt...</p>
          )}
        </Tabs.Item>
      </Tabs>
    </div>
  );
};
