import "./Lampe.css";

export const Lampe = ({ modus, landscape }) => {
  return (
    <>
      <div
        className={
          "lampe_hintergrundlicht" + (modus === "enabled" ? " enabled" : "")
        }
      ></div>
      <div
        className={"lampe_aus" + (modus === "enabled" ? " enabled" : "")}
      ></div>
      <div className={"lampe_an" + (modus === "enabled" ? " enabled" : "")}>
        <div
          className={
            "lampe_schatten_transitionline" +
            (modus === "enabled" ? (landscape ? " enabledls" : " enabled") : "")
          }
        ></div>
      </div>
      <div
        className={"lampe_schatten" + (modus === "enabled" ? " enabled" : "")}
      >
        <div className="lampe_schatten_links"></div>
        <div
          className={
            "lampe_schatten_zentrum" +
            (modus === "enabled" ? (landscape ? " enabledls" : " enabled") : "")
          }
        ></div>
        <div className="lampe_schatten_rechts"></div>
      </div>
    </>
  );
};
