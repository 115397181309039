import { Button, Card } from "flowbite-react";
import moment from "moment";
import "moment/locale/de";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useConfig } from "../../../context/ConfigContext";
import { useEffect } from "react";

export const LegalCard = ({ agb, art }) => {
  const config = useConfig();
  const { Datum, Description, Path } = agb;
  moment.locale("de");
  const formattedDate = moment(Datum).format("DD. MMMM YYYY");

  useEffect(() => {
    if (!config || !config.apiBaseUrl) {
      return null;
    }
  }, [config]);

  return (
    <>
      {config && config.apiBaseUrl && (
        <Card className="my-4">
          <h2 className="font-normal my-0">
            {art === "agb" ? "AGB" : "DSR"} Stand: {formattedDate}
          </h2>
          <p className="mb-4">{Description}</p>
          {art === "agb" ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${config.apiBaseUrl}${
                art === "agb" ? "/agb" : "/dsr"
              }${Path}`}
            >
              <Button
                pill
                style={{
                  color: "#abcfd3",
                  background: "rgba(171, 207, 211, 0.16)",
                  border: "1px solid #abcfd3",
                  padding: "6px 0",
                }}
              >
                Lade die {art === "agb" ? "AGB" : "DSR"} mit Stand vom{" "}
                {formattedDate} bei Bedarf hier als PDF herunter
                <HiOutlineArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </a>
          ) : (
            <a href="mailto:a.schaefer@primagency.de?subject=datenschutzrichtlinie">
              <Button
                pill
                style={{
                  color: "#abcfd3",
                  background: "rgba(171, 207, 211, 0.16)",
                  border: "1px solid #abcfd3",
                  padding: "6px 0",
                }}
              >
                {" "}
                Frage die aktuelle DSR mit Stand vom {formattedDate} bei Bedarf
                hier an.
                <HiOutlineArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </a>
          )}
        </Card>
      )}
    </>
  );
};
